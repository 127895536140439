<template>

  <section id="dashboard" class="mt-4">

    <div class="container">
        <div class="row">

            <SideNav v-bind:selected="5"/>

            <div class="col-xs-12 col-sm-12 col-md-12 mt-md-0">
                <form id="payment_method" class="mt-2">

                    <div class="row">
                        <div class="col-12 mb-2">
                          <input id="email" type="email" name="email" placeholder="E-mail address" readonly v-model="email" disabled />
                        </div>
                        <div class="col-12">
                          <input id="username" type="text" name="username" placeholder="Username" readonly v-model="username" disabled/>
                        </div>
                    </div>

                    <div class="mb-2"></div>
                    <label class="mb-2 small"><option value="method">Delivery Address</option></label>

                    <div class="row">
                        <div class="col-sm-12 col-md-6 mb-2">
                        <input id="firstname" type="text" name="first_name" v-model="firstName" placeholder="First Name"/>
                        </div>
                        <div class="col-sm-12 col-md-6">
                        <input id="lastname" type="text" name="last_name" v-model="lastName" placeholder="Last Name"/>
                        </div>
                    </div>

                    <div class="mb-2"></div>


                    <div class="row">
                        <div class="col-sm-12 col-md-6 mb-2">
                        <input id="house_no" type="text" name="house_no" v-model="secondLine" placeholder="House or flat number"/>
                        </div>
                        <div class="col-sm-12 col-md-6">
                        <input id="firstline" type="text" name="firstline" v-model="firstLine" placeholder="First line of address"/>
                        </div>
                    </div>

                    <div class="mb-2"></div>

                        <div class="row">
                          <div class="col-sm-12 col-md-6 mb-2">
                              <input id="city" type="text" name="city" v-model="city" placeholder="City"/>
                              </div>
                          <div class="col-sm-12 col-md-6">
                              <input id="postcode" type="text" name="postcode" v-model="postCode" placeholder="Post Code"/>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6">
                            <input id="phoneNumber" type="text" name="phoneNumber" v-model="phoneNumber" placeholder="Phone Number"/>
                        </div>


                  <div class="total mt-3">



                    <div class="row mt-3">
                      <div class="col">

                      </div>
                      <div class="col">
                        <button v-on:click="SaveChanges" type="button" class="btn btn-custom mt-3">Save</button> 
                      </div>
                    </div>



                  </div>
                </form>
            </div>
        </div>
    </div>
  </section>

  <Footer/>
</template>

<script>

import Api from '../../scripts/api'

import Footer from '../../components/global/Footer'
import SideNav from '../../components/dashboard/SideNavigator'

export default {
    components: {
        Footer,
        SideNav
    },
    props: {
      account: Object
    },
    data() {
      return {
        email: '',
        username: '',

        city: '',
        postCode: '',
        firstLine: '',
        secondLine: '',

        firstName: '',
        lastName: '',

        phoneNumber: ''
      }
    },
    watch: {
      account: {
        immediate: true,
        handler (newAccount) {
          if(newAccount) {
            this.email = newAccount.account.email.email;
            this.firstName = newAccount.account.firstName;
            this.lastName = newAccount.account.lastName;
            this.username = newAccount.account.username;
            this.city = newAccount.account.address.city;
            this.postCode = newAccount.account.address.postCode;
            this.firstLine = newAccount.account.address.firstLine;
            this.secondLine = newAccount.account.address.secondLine;
            this.county = newAccount.account.address.county;
            this.phoneNumber = newAccount.account.phoneNumber
          }
        }
      }
    },
    methods: {
      SaveChanges: async function() {
        var result = await Api.changeDetails(this.firstName, this.lastName, this.city, this.postCode, this.firstLine, this.secondLine, this.phoneNumber);
        if(result.status != 200) return this.$toast.warning('Failed to changed details, try again later...');
        this.$toast.success('Details updated successfully.');
      }
    }
}
</script>